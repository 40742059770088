import { render, staticRenderFns } from "./UserDeclinedView.vue?vue&type=template&id=0ab0bc08&scoped=true&lang=pug&"
import script from "./UserDeclinedView.vue?vue&type=script&lang=ts&"
export * from "./UserDeclinedView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ab0bc08",
  null
  
)

export default component.exports